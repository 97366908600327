import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { format } from 'date-fns';

import { Icon } from 'common/components/Icon';
import { Typography } from 'common/components/Typography';
import InputDate from 'common/components/InputDate';
import Button from '@rambler-components/button';

import { getTop100Markup } from 'common/utils/getTop100Markup';
import { useTop100Context } from 'common/contexts/top100Context';

import { useChinese } from '../../Form/hooks/useChinese';
import { useDashboard } from '../../Form/hooks/useDashboard';

import s from './styles.css';

const TABS = [
  {
    name:  'physical',
    icon:  'physical',
    label: 'Сила',
    title: 'Физический биоритм',
    text:  'Отвечает за самочувствие и энергичность. Затяжная работа, физические нагрузки и интимные контакты лучше всего даются в позитивной фазе биоритма. В спортзале можно поставить новый личный рекорд. В отрицательной фазе хочется лежать и есть меньше, чем обычно.',
  },
  {
    name:  'intellectual',
    icon:  'intellectual',
    label: 'Разум',
    title: 'Интеллектуальный биоритм',
    text:  'Отвечает за работу памяти, мышление и внимательность. Если вам нужно принять важное решение или выполнить сложную работу, дождитесь позитивной фазы. В отрицательной фазе вероятность ошибки повышается — не планируйте на это время ответственных дел.',
  },
  {
    name:  'emotional',
    icon:  'emotional',
    label: 'Эмоции',
    title: 'Эмоциональный биоритм',
    text:  'Отвечает за настроение и ваше поведение в обществе. Чтобы расположить к себе людей и продемонстрировать себя перед начальством, выбирайте дни позитивной фазы. В отрицательную фазу появится раздражительность — старайтесь не обидеть окружающих.',
  },
];

const PERCENTS: Record<string, number> = {
  physical:     50,
  intellectual: 60,
  emotional:    30,
};

export const BiorhythmWithoutAccount: React.FC = React.memo(() => {
  const isMobile = useSelector((state: IAppState) => state.runtime.isMobile);

  const [activeTab, setActiveTab] = useState(TABS[1].name);
  const [date, setDate] = useState('');
  const [error, setError] = useState(false);

  const {
    checkChineseDateAction,
  } = useChinese();
  const {
    checkDashboardDateAction,
  } = useDashboard();
  const {
    top100Prefix,
  } = useTop100Context();

  const contentData = TABS.find(({ name }) => name === activeTab);

  const onCalculateBiorhythm = () => {
    if (!date) {
      setError(true);
      return;
    }
    const birthDate = format(new Date(date), 'yyyy-MM-dd');

    checkChineseDateAction('dashboard_personal', birthDate);
    checkDashboardDateAction('dashboard_personal', birthDate);
  };

  return (
    <div className={cn(s.root, s[contentData?.icon as string], isMobile && s.mobile)}>
      <Typography
        className={s.title}
        variant="h1"
      >
        Биоритмы сегодня
      </Typography>
      <Typography
        className={s.mainText}
        variant="defaultMedium"
        component="p"
      >
        В какие-то дни мы легко преуспеваем во всем, а в какие-то тонем в ворохе неприятностей.
        От чего это зависит? От&nbsp;фазы биоритма в конкретный момент.
      </Typography>
      <div className={s.content}>
        <div className={s.tabsContent}>
          <div className={s.scroll}>
            <div className={s.tabs}>
              {TABS.map(({ name, label }) => (
                <button
                  type="button"
                  key={name}
                  className={cn(s.tab, activeTab === name && s.active)}
                  onClick={() => setActiveTab(name)}
                  {...getTop100Markup(isMobile, `${top100Prefix}::biorhythm_without_account`, `tab::${name}`)}
                >
                  <Icon id={`biorhythm-${name}`} className={s.tabIcon} />
                  <Typography variant="defaultMedium">
                    {label}
                  </Typography>
                </button>
              ))}
            </div>
          </div>
          <div className={s.tabsText}>
            <Typography variant="h3" className={s.tabsTitle}>{contentData?.title}</Typography>
            <Typography variant="defaultMedium" className={s.text}>{contentData?.text}</Typography>
          </div>
        </div>
        <div className={s.graph}>
          {TABS.map(({ name, title }) => (
            <button
              key={name}
              aria-label={title}
              type="button"
              className={cn(
                s.tube,
                contentData?.name === name && s.activeTube,
                s[name],
              )}
              onClick={() => setActiveTab(name)}
              {...getTop100Markup(isMobile, `${top100Prefix}::biorhythm_without_account`, `tube::${name}`)}
            >
              <div className={s.inner} style={{ height: `${PERCENTS[name]}%` }} />
            </button>
          ))}
        </div>
      </div>
      <div className={s.form}>
        <div className={s.dateInput}>
          <InputDate
            placeholder="Укажите дату рождения"
            isMobile={isMobile}
            type="border"
            value={date}
            status={error ? 'error' : undefined}
            onChange={(value: string) => {
              setDate(value);
              setError(false);
            }}
            {...getTop100Markup(isMobile, `${top100Prefix}::biorhythm_without_account`, 'date_input')}
          />
        </div>
        <Button
          onClick={onCalculateBiorhythm}
          {...getTop100Markup(isMobile, `${top100Prefix}::biorhythm_without_account`, 'calculate_button')}
        >
          Рассчитать биоритмы
        </Button>
      </div>
    </div>
  );
});
