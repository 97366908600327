import { Request } from 'express';
import Cookies from 'js-cookie';
import _cloneDeep from 'lodash.clonedeep';

import { snackbarEvent } from '@rambler-components/snackbar';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { GENDER } from 'config/constants/gender';
import { ONBOARDING_EXPIRES } from 'config/constants/content';

import { getSignByDate } from 'common/utils/signByDate';
import { safeGet } from 'utils/safeGet';
import { APP_THEME } from 'config/constants';
import { AppStore } from './configure';
import { SET_RUNTIME_VARIABLE } from './runtime';

export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';

const initialState: AccountType = {
  first_name: '',
  gender:     GENDER.unknown,
  birthday:   null,
  birthtime:  null,
  birthplace: '',
  sign:       null,
  partners:   [],
  theme:      APP_THEME.Sign,
};

export const fetchAccountData = (
  fetchName: keyof APIsUrls['account'],
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
  { req }: { req: Request },
) => {
  try {
    const { rsid } = getState().runtime;
    let data: AccountType;

    if (rsid) {
      const url = `${API.account[fetchName]}`;
      // Redis null т.к. эту ручку кешировать нельзя
      data = await fetch(url, 'GET', getState(), undefined);

      if (!data) {
        const err = new Error(`${new Date()} Не удалось получить данные аккаунта: ${url}`);
        throw err;
      }
    } else if (__SERVER__ && req.cookies && req.cookies.haccount) {
      data = JSON.parse(req.cookies.haccount);
    } else if (Cookies.get('haccount')) {
      data = JSON.parse(Cookies.get('haccount')!);
    } else {
      data = _cloneDeep(initialState);
    }

    // Выставляем тему по умолчанию
    if (!data.theme) data.theme = APP_THEME.Sign;

    if (data?.birthtime?.length) {
      // время должно быть формата hh:mm
      // а из ручки может прийти hh:mm:ss
      const [hour, min] = data.birthtime.split(':');
      data.birthtime = `${hour}:${min}`;
    }

    dispatch({
      type: GET_ACCOUNT_DATA,
      data,
    });
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);
  }
};

export const sendAccountData = (
  account: AccountType,
  hideOnboarding = true,
  withSnackbar = false,
) => async (
  dispatch: AppStore['dispatch'],
  getState: AppStore['getState'],
) => {
  try {
    const { rsid } = getState().runtime;
    let data: AccountType;

    if (rsid) {
      // Redis null т.к. эту ручку кешировать нельзя
      data = await fetch(API.account.users, 'PUT', getState(), undefined, account);

      if (!data) {
        const err = new Error(`${new Date()} Не удалось отправить данные аккаунта: ${API.account.users}`);
        throw err;
      }
    } else {
      data = { ...account };

      data.sign = getSignByDate(data.birthday || '');

      const partner = safeGet(() => data.partners && data.partners[0]);
      if (partner) {
        partner.sign = getSignByDate(partner.birthday || '');
      }

      const isAccountSave = Cookies.get('haccount');

      if (
        !data.birthday
        && !data.birthtime
        && data.gender === 'unknown'
        && isAccountSave) {
        Cookies.remove('haccount');
      } else {
        // expires: If omitted, the cookie becomes a session cookie
        Cookies.set('haccount', JSON.stringify(data));
      }
    }

    if (hideOnboarding && Cookies.get('onboardingShown') !== 'true') {
      dispatch({
        type:  SET_RUNTIME_VARIABLE,
        name:  'onboardingShown',
        value: true,
      });

      Cookies.set('onboardingShown', 'true', { expires: ONBOARDING_EXPIRES });
    }

    dispatch({
      type: SET_ACCOUNT_DATA,
      data,
    });

    if (withSnackbar) {
      snackbarEvent({
        message:           'Запомнили ваши данные',
        align:             'bottom center',
        withCloseButton:   true,
        autoCloseDuration: 5000,
      });
    }
  } catch (error) {
    sentryReactSend(error);
    // eslint-disable-next-line no-console
    console.error(`${new Date()} ${error}`);

    if (withSnackbar) {
      snackbarEvent({
        message:           'Что-то не выходит. Попробуйте ещё раз',
        type:              'error',
        align:             'bottom center',
        withCloseButton:   true,
        autoCloseDuration: 5000,
      });
    }
  }
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'account'> = (state = initialState, action) => {
  // Деструктурировать на этом уровне нельзя, так как рушится типизация
  switch (action.type) {
    case GET_ACCOUNT_DATA:
      return {
        ...state,
        ...action.data,
      };
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default reducer;
