import React from 'react';
import { useSelector } from 'react-redux';
import FooterComponent from '@rambler-components/footer';
import '@rambler-components/footer/styles.css';
import { createSimpleLinks, socialFooterLinks } from 'config/constants/footer';

function Footer() {
  const { isMobile } = useSelector((state: IAppState) => state.runtime);

  return (
    <FooterComponent
      type="white"
      socialLinks={socialFooterLinks}
      simpleLinks={createSimpleLinks(isMobile)}
    />
  );
}

export default Footer;
