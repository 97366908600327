import { YANDEX_METRICS } from 'config/constants/counters';
import { APP_VERSION } from 'config/constants';

import top100Wrapper from 'utils/counters/top100Wrapper';
import { sentryReactSend } from 'utils/sentry/client';

let referer = __CLIENT__
  ? `${window.location.pathname}${window.location.search}${window.location.hash}`
  : '';

// TODO пока не убрал version, потому что если понадобиться - много где опять нужно
// прокидывать аргументом
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateCounters = (version?: APP_VERSION) => {
  const {
    pathname,
    search,
    hash,
  } = window.location;
  const url = `${pathname}${search}${hash}`;

  if (window.ga) {
    window.ga('send', 'pageview');
  }

  try {
    top100Wrapper.trackPageview();
  } catch (error) {
    sentryReactSend(error);
  }

  const ids = [
    YANDEX_METRICS.COMMON,
    YANDEX_METRICS.PORTAL,
  ];

  if (window.ym) {
    ids.forEach(id => {
      const yaCounterId: string = `yaCounter${id}`;
      const counter = (window as { [key: string]: any })[yaCounterId];
      if (!counter) return;
      window.ym(id, 'hit', url, { referer });
    });
  }

  if (window.tnsCounterRambler_ru && window.tnsID) {
    window.tnsCounterRambler_ru.hit(window.tnsID, pathname);
  }
  referer = url;
};

export default updateCounters;
